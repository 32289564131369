<template>
    <div class="material-drawer">
        <a-drawer placement="bottom" :mask="false" :closable="true" :visible="visible" height="144" wrapClassName="material-drawer-wrap" @close="onClose">
            <template #title> Change Material </template>

            <a-spin v-if="loading" />
            <div class="material-tray" v-else>
                <div class="material-grid">
                    <a-tooltip v-for="(material, index) in materials" :key="index">
                        <template slot="title">
                            {{ material.name }}
                        </template>
                        <button @click="onClick(material)" class="material-grid-item" :class="{ active: $store.state.texture.id == material.id }">
                            <img :src="material.path" alt="" />
                        </button>
                    </a-tooltip>
                </div>
            </div>
            <a-empty v-if="loading == false && materials.length == 0" :description="false" />
        </a-drawer>
    </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
    name: "MaterialSelector",
    computed: mapState(["product"]),
    data() {
        return {
            visible: false,
            loading: false,
            selected: null,
            materials: [],
        };
    },
    mounted() {},
    methods: {
        onClick(material) {
            this.$store.commit("onChangeMaterial", {
                id: material.id,
                name: material.name,
                file: material.path,
            });
        },
        async load(id) {
            this.loading = true;
            const {
                data: { data },
            } = await axios.get("https://pefabricpark.com/api/variants?product_id=" + id);
            this.materials = data;
            this.loading = false;
        },
        onClose() {
            this.visible = false;
        },
    },
    watch: {
        product(newObj, oldObj) {
            console.log(newObj.name, oldObj.name);
            this.visible = newObj.name ? true : false;
            this.load(newObj.id);
        },
    },
};
</script>

<style lang="scss">
.material-drawer {
    &-btn {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
    }
}

.material-drawer-wrap {
    .ant-drawer-content-wrapper {
        display: flex;
        justify-content: center;
        box-shadow: none !important;
        .ant-drawer-content {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;

            .ant-drawer-body {
                padding: 12px;
                padding-top: 20px;
            }

            .material-grid {
                display: flex;
                gap: 12px;

                button {
                    height: 56px;
                    width: 56px;
                    border: none;
                    overflow: hidden;
                    cursor: pointer;
                    border-radius: 5px;
                    border: 2px solid transparent;

                    &.active {
                        border-color: #b58445;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
