<template>
    <div class="loading-window">
        <div class="loading-window-inner">
            <a-spin size="large" />
            <slot />
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.loading-window {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>
