<template>
    <div class="product-drawer">
        <a-drawer placement="right" :mask="false" :closable="true" :visible="visible" width="320" wrapClassName="product-drawer-wrap" @close="onClose">
            <template #title> Select Product </template>
            <div class="product-search">
                <a-form>
                    <a-form-item style="margin-bottom: 0">
                        <a-input v-model="filter" placeholder="Search Product" />
                        <a-select style="width: 100%" v-model="category">
                            <a-select-option value=""> All Categories </a-select-option>
                            <a-select-option v-for="(category, index) in categories" :key="index" :value="category.id">
                                {{ category.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-form>
            </div>
            <a-divider style="margin: 12px 0" />
            <div class="product-spin" v-if="loading">
                <a-spin />
            </div>
            <div class="product-grid" v-else>
                <a-popover v-for="(product, index) in products" :key="index" :title="product.name" placement="left">
                    <template slot="content">
                        <img :src="product.path" :alt="product.name" />
                    </template>
                    <button @click="onClick(product)" :class="{ active: $store.state.product.id == product.id }">
                        <img :src="product.path" :alt="product.name" />
                    </button>
                </a-popover>
            </div>
            <a-empty v-if="loading == false && products.length == 0" :description="false" />
            <div class="product-pagination">
                <a-button-group>
                    <a-button type="primary" :disabled="!prev" @click="load(current - 1)"> <a-icon type="left" />Go back </a-button>
                    <a-button type="primary" :disabled="!next" @click="load(current + 1)"> Go forward<a-icon type="right" /> </a-button>
                </a-button-group>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

import debounce from "lodash/debounce";

export default {
    name: "ProductSelector",
    computed: mapState(["option"]),
    data() {
        return {
            visible: false,
            loading: false,
            filter: "",
            categories: [],
            category: "",
            selected: null,
            products: [],
            current: null,
            prev: false,
            next: false,
        };
    },
    methods: {
        onClick(product) {
            this.$store.commit("onChangeProduct", product);
        },
        async loadCategories() {
            const { data } = await axios.get("https://pefabricpark.com/api/categories");
            this.categories = data;
        },
        async load(page = 1) {
            this.loading = true;
            let category = this.category ? "&category_id=" + this.category : "";
            let filter = this.filter ? "&filter=" + this.filter : "";
            // let pagen = page ? "&page=" + page : 1;
            const { data } = await axios.get("https://pefabricpark.com/api/products?per_page=9&page=" + page + category + filter);
            this.products = data.data;
            this.current = data.meta.current_page;
            this.next = data.links.next ? true : false;
            this.prev = data.links.prev ? true : false;
            this.loading = false;
        },
        onClose() {
            this.visible = false;
        },
    },
    watch: {
        option(newObj, oldObj) {
            console.log(newObj.name, oldObj.name);
            this.visible = newObj.name ? true : false;
        },
        visible(newVal, PreVal) {
            console.log(newVal, PreVal);
            newVal ? this.load() : null;
            newVal ? this.loadCategories() : null;
        },
        filter: {
            handler: debounce(function (newVal, PreVal) {
                console.log(newVal, PreVal);
                this.load();
            }, 300),
        },
        category: {
            handler: debounce(function (newVal, PreVal) {
                console.log(newVal, PreVal);
                this.load();
            }, 300),
        },
    },
};
</script>

<style lang="scss">
.product-drawer {
    &-btn {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
    }
}

.product-drawer-wrap {
    .ant-drawer-content-wrapper {
        display: flex;
        align-items: center;
        box-shadow: none !important;
        padding: 20px;
        padding-left: 0;
        .ant-drawer-content {
            height: auto;
            border-radius: 12px;

            .ant-drawer-body {
                padding: 24px;
            }

            .product-search {
                position: relative;
            }
            .product-spin {
                text-align: center;
            }
            .product-pagination {
                text-align: center;
                margin-top: 25px;
            }
            .product-grid {
                display: grid;
                gap: 5px;
                grid-template-columns: repeat(3, 1fr);

                button {
                    height: 80px;
                    width: 80px;
                    border: none;
                    overflow: hidden;
                    cursor: pointer;
                    border-radius: 5px;
                    border: 2px solid transparent;

                    &.active {
                        border-color: #b58445;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
