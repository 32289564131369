<template>
    <div class="canvas__wrap" ref="canvas">
        <loading v-if="loading.status">
            {{ loading.value }}
        </loading>
        <div class="options">
            <a-space>
                <a-switch checked-children="Outer" un-checked-children="Outer" disabled v-model="isOption.cushion_hand" />
                <a-switch checked-children="Seat" un-checked-children="Seat" disabled v-model="isOption.cushion_seat" />
                <a-switch checked-children="Pillow" un-checked-children="Pillow" v-model="isOption.pillow" />
                <a-switch checked-children="Curtain" un-checked-children="Curtain" disabled v-model="isOption.curtain" />
            </a-space>
        </div>
    </div>
</template>

<style lang="scss">
.canvas__wrap {
    .options {
        position: fixed;
        left: 0;
        right: 0;
        margin: auto;
        top: 120px;
        max-width: 200px;
    }
}
</style>

<script>
import { mapState } from "vuex";

import Loading from "./Partials/Loading.vue";

// @ is an alias to /src
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

export default {
    name: "CanvasEditor",
    components: {
        Loading,
    },
    computed: mapState(["object", "texture", "option"]),
    data: function () {
        const scene = new THREE.Scene();
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);
        const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.61);
        const dirLight = new THREE.DirectionalLight(0xffffff, 0.54);
        const controls = new OrbitControls(camera, renderer.domElement);

        const floorGeometry = new THREE.PlaneGeometry(5000, 5000, 1, 1);
        const floorMaterial = new THREE.MeshPhongMaterial({
            // color: 0xeeeeee,
            color: 0xdddddd,
            shininess: 0,
        });
        const floor = new THREE.Mesh(floorGeometry, floorMaterial);

        const initialMaterial = new THREE.MeshPhongMaterial({ color: 0x3e2f22, shininess: 10 });
        const initialWhiteMaterial = new THREE.MeshPhongMaterial({ color: 0xffffff, shininess: 10 });
        const initialMap = [
            { childID: "cushion_hand", mtl: initialMaterial },
            { childID: "cushion_seat", mtl: initialMaterial },
            { childID: "leg", mtl: initialMaterial },
            { childID: "pillow", mtl: initialMaterial },
            { childID: "bottom", mtl: initialMaterial },
            { childID: "curtain", mtl: initialWhiteMaterial },
        ];

        return {
            scene: scene,
            renderer: renderer,
            camera: camera,
            hemiLight: hemiLight,
            dirLight: dirLight,
            controls: controls,
            floor: floor,
            initRotate: 0,
            initialMaterial: initialMaterial,
            initialMap: initialMap,
            curtain: null,
            model: null,
            editable: null,
            isOption: {
                cushion_hand: true,
                cushion_seat: true,
                pillow: false,
                curtain: false,
            },
            material: {
                cushion_hand: null,
                cushion_seat: null,
                pillow: null,
                curtain_outer: null,
            },
            loading: {
                status: false,
                value: null,
                sofa: null,
                curtain: null,
            },
        };
    },
    created() {
        this.scene.background = new THREE.Color(0xf1f1f1);
        this.scene.fog = new THREE.Fog(0xf1f1f1, 20, 100);

        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMapType = THREE.PCFSoftShadowMap;
        this.renderer.setPixelRatio(window.devicePixelRatio);

        // this.camera.position.z = 7;
        this.camera.position.z = 5;
        this.camera.position.x = 0;
        // this.camera.position.y = 4;
        this.camera.position.y = 6;
        this.scene.add(this.camera);

        this.hemiLight.position.set(0, 50, 0);
        this.scene.add(this.hemiLight);

        this.dirLight.position.set(-8, 12, 8);
        this.dirLight.castShadow = true;
        this.dirLight.shadow.radius = 8;
        this.dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
        this.scene.add(this.dirLight);

        this.controls.maxPolarAngle = Math.PI / 2;
        this.controls.minPolarAngle = Math.PI / 3;
        this.controls.maxAzimuthAngle = Math.PI / 3;
        this.controls.minAzimuthAngle = -Math.PI / 3;
        this.controls.enableDamping = true;
        this.controls.enablePan = true;
        this.controls.dampingFactor = 0.1;
        this.controls.autoRotate = false; // Toggle this if you'd like the chair to automatically rotate
        this.controls.autoRotateSpeed = 4; // 30
        this.controls.minDistance = 4; // don't let user zoom too close
        this.controls.maxDistance = 50; // don't let user zoom too far away

        this.floor.rotation.x = -0.5 * Math.PI;
        this.floor.receiveShadow = true;
        this.floor.position.y = -1;
        this.scene.add(this.floor);
    },
    mounted() {
        this.$refs.canvas.appendChild(this.renderer.domElement);
        this.animate();
    },
    methods: {
        animate: function () {
            this.controls.update();
            this.renderer.render(this.scene, this.camera);
            requestAnimationFrame(this.animate);

            if (this.resizeRenderer(this.renderer)) {
                const canvas = this.renderer.domElement;
                this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
                this.camera.updateProjectionMatrix();
            }
        },
        resizeRenderer: function (renderer) {
            const canvas = renderer.domElement;
            let width = window.innerWidth;
            let height = window.innerHeight;
            let canvasPixelWidth = canvas.width / window.devicePixelRatio;
            let canvasPixelHeight = canvas.height / window.devicePixelRatio;

            const needResize = canvasPixelWidth !== width || canvasPixelHeight !== height;
            if (needResize) {
                renderer.setSize(width, height, false);
            }
            return needResize;
        },
        initialRotation: function () {
            this.initRotate++;
            if (this.initRotate <= 120) {
                this.model.rotation.y += Math.PI / 60;
            }
        },
        initColor: function (parent, type, mtl) {
            if (parent) {
                parent.traverse((o) => {
                    if (o.isMesh) {
                        if (o.name.includes(type)) {
                            o.castShadow = true;
                            o.receiveShadow = true;
                            o.material = mtl;
                            o.nameID = type; // Set a new property to identify this object
                        }
                    }
                });
            }
        },
        onLoadObject: function (path) {
            // Init the object loader
            var loader = new GLTFLoader();
            this.scene.remove(this.model);
            this.loading.status = true;
            this.loading.value = 0;
            loader.load(
                "./object/" + path,
                (gltf) => {
                    this.model = gltf.scene;
                    // Set the models initial scale
                    this.model.scale.set(2, 2, 2);
                    this.model.rotation.y = Math.PI;
                    // Offset the y position a bit
                    this.model.position.y = -1;
                    // Set initial textures
                    for (let object of this.initialMap) {
                        this.initColor(this.model, object.childID, object.mtl);
                    }

                    // this.onLoadTexture("./texture/1.jpg", "cushion_hand");
                    // this.onLoadTexture("./texture/1.jpg", "cushion_seat");
                    // this.onLoadTexture("./texture/1.jpg", "pillow");
                    this.onSetOldTexture();
                    this.onChangeVisibile();

                    // Add the model to the scene
                    this.scene.add(this.model);
                    // this.loading.status = false;
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
                    this.loading.sofa = Math.round((xhr.loaded / xhr.total) * 100);
                },
                (error) => {
                    console.error(error);
                }
            );
        },
        onSetOldTexture: function () {
            Object.entries(this.material).forEach(([key, value]) => {
                console.log(`${key}: ${value}`);
                if (value) {
                    this.onLoadTexture(value, key);
                } else {
                    this.onLoadTexture("./texture/1.jpg", key);
                }
            });
        },
        onLoadTexture: function (path, type = null) {
            // console.log(path);
            // const newMaterial = new THREE.MeshPhongMaterial({ color: 0xff0000, shininess: 10 });
            let changeble = type ? type : this.editable;
            let txt = new THREE.TextureLoader().load(path);
            // let repeat = changeble == "curtain_outer" ? 3 : changeble == "cushion_hand cushion_seat" ? 3 : 1;
            let repeat = 1;
            if (changeble == "curtain_outer") {
                repeat = 4;
            }
            if (changeble == "cushion_hand") {
                repeat = 5;
            }
            if (changeble == "cushion_seat") {
                repeat = 6;
            }

            // txt.repeat.set(color.size[0], color.size[1], color.size[2]);
            txt.wrapS = THREE.RepeatWrapping;
            txt.wrapT = THREE.RepeatWrapping;
            txt.repeat.set(repeat, repeat);

            const newMaterial = new THREE.MeshPhongMaterial({
                map: txt,
                shininess: 10,
            });

            if (changeble == "curtain_outer") {
                this.initColor(this.curtain, changeble, newMaterial);
            } else {
                this.initColor(this.model, changeble, newMaterial);
            }

            this.material[changeble] = path;
        },
        onLoadOption: function (code) {
            this.editable = code;
        },
        onChangeVisibile: function (options = null) {
            let items = options ? options : this.isOption;
            Object.entries(items).forEach(([key, value]) => {
                if (this.curtain) {
                    this.curtain.traverse((o) => {
                        // console.log(o.name);
                        if (o.isMesh) {
                            if (o.name.includes(key)) {
                                o.visible = value;
                            }
                        }
                    });
                }
                if (this.model) {
                    this.model.traverse((o) => {
                        if (o.isMesh) {
                            if (o.name.includes(key)) {
                                o.visible = value;
                            }
                        }
                    });
                }
            });
        },
        onLoadCurtain: function () {
            // Init the object loader
            var loader = new GLTFLoader();
            this.scene.remove(this.curtain);
            this.loading.status = true;
            this.loading.value = 0;
            loader.load(
                "./object/curtain.glb",
                (gltf) => {
                    this.curtain = gltf.scene;
                    // Set the models initial scale
                    this.curtain.scale.set(2, 2, 2);
                    // this.curtain.rotation.y = Math.PI;
                    // Offset the y position a bit
                    this.curtain.position.y = -1;
                    this.curtain.position.z = -2.5;
                    // Set initial textures
                    for (let object of this.initialMap) {
                        this.initColor(this.curtain, object.childID, object.mtl);
                    }

                    this.onSetOldTexture();
                    this.onChangeVisibile();

                    // Add the model to the scene
                    this.scene.add(this.curtain);
                    // this.loading.status = false;
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
                    this.loading.curtain = Math.round((xhr.loaded / xhr.total) * 100);
                },
                (error) => {
                    console.error(error);
                }
            );
        },
        addCart: function () {
            this.$store.commit("onChangeCart", {
                mat: this.texture,
                option: this.editable,
            });
        },
    },
    watch: {
        object(newObj, oldObj) {
            console.log(newObj.name, oldObj.name);
            this.onLoadObject(newObj.file);
            this.onLoadCurtain();
        },
        texture(newTex, oldTex) {
            console.log(newTex.name, oldTex.name);
            this.onLoadTexture(newTex.file);
            this.addCart();
        },
        option(newOp, oldOp) {
            console.log(newOp.name, oldOp.name);
            this.onLoadOption(newOp.code);
        },
        isOption: {
            deep: true,
            handler: function (newOp, oldOp) {
                console.log(newOp, oldOp);
                this.onChangeVisibile(newOp);
            },
        },
        loading: {
            deep: true,
            handler: function (newLo) {
                let val = (newLo.sofa + newLo.curtain) / 2;
                this.loading.value = val + "%";
                this.loading.status = val == 100 ? false : true;
            },
        },
    },
};
</script>
