<template>
    <div class="customizer-view">
        <div class="brand-name">
            <router-link to="/"><img src="/image/logo.svg" /></router-link>
        </div>
        <canvas-editor />
        <object-selector />
        <material-selector />
        <option-selector />
        <product-selector />
        <introduction-popup />
        <cart-section />
    </div>
</template>

<script>
import CanvasEditor from "./CanvasEditor/Index";

import ObjectSelector from "./ObjectSelector/Index";
import MaterialSelector from "./MaterialSelector/Index";
import OptionSelector from "./OptionSelector/Index";
import ProductSelector from "./ProductSelector/Index";

import IntroductionPopup from "./IntroductionPopup/Index";
import CartSection from "./CartSection/Index";

export default {
    name: "Customizer",
    components: {
        CanvasEditor,
        ObjectSelector,
        MaterialSelector,
        OptionSelector,
        ProductSelector,
        IntroductionPopup,
        CartSection,
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss">
.customizer-view {
    height: 100vh;
    .brand-name {
        top: 0;
        left: 0;
        position: absolute;
        z-index: 2;

        a {
            background-color: white;
            display: inline-block;
            padding: 15px;
            border-radius: 5px;
            margin: 15px;

            img {
                display: flex;
            }
        }
    }
}
</style>
