<template>
    <div class="object-drawer">
        <a-drawer placement="top" :mask="false" :closable="false" :visible="true" height="88" wrapClassName="object-drawer-wrap">
            <div class="object-grid">
                <a-popover v-for="(object, index) in objects" :key="index" :title="object.name">
                    <template slot="content">
                        <img :src="`/object/${object.image}`" :alt="object.image" />
                    </template>

                    <button @click="onClick(object)" :class="{ active: $store.state.object.name == object.name }">
                        <img :src="`/object/${object.image}`" :alt="object.name" />
                    </button>
                </a-popover>
            </div>
        </a-drawer>
    </div>
</template>

<script>
export default {
    name: "ObjectSelector",
    data() {
        return {
            visible: false,
            selected: null,
            objects: [
                {
                    name: "Type One",
                    file: "1.glb",
                    image: "1.png",
                },
                {
                    name: "Type Two",
                    file: "2.glb",
                    image: "2.png",
                },
                {
                    name: "Type Three",
                    file: "3.glb",
                    image: "3.png",
                },
                {
                    name: "Type Four",
                    file: "4.glb",
                    image: "4.png",
                },
                {
                    name: "Type Five",
                    file: "5.glb",
                    image: "5.png",
                },
            ],
        };
    },
    methods: {
        onClick(object) {
            this.$store.commit("onChangeObject", object);
        },
    },
};
</script>

<style lang="scss">
.object-drawer {
    &-btn {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
    }
}

.object-drawer-wrap {
    .ant-drawer-content-wrapper {
        display: flex;
        justify-content: center;
        box-shadow: none !important;
        .ant-drawer-content {
            width: auto;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;

            .ant-drawer-body {
                padding: 12px;
                padding-top: 20px;
            }

            .object-grid {
                display: flex;
                gap: 12px;

                button {
                    height: 56px;
                    width: 56px;
                    border: none;
                    overflow: hidden;
                    cursor: pointer;
                    border-radius: 5px;
                    border: 2px solid transparent;

                    &.active {
                        border-color: #b58445;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
