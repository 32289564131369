<template>
    <div class="introduction-modal">
        <a-modal v-model="visible" centered :width="650" :closable="false" :maskClosable="false" :footer="null">
            <div class="introduction-content">
                <h3>Welcome to P.E.Fabric Park sofa customization. </h3> 
                <p>
                    Customize the sofa structures to your liking using our fabrics
                </p>
                <div class="intro-video">
                    <video width="320" height="240" controls autoplay ref="videoPlayer">
                        <source src="/video/intro-video.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: mapState(["object"]),
    data() {
        return {
            visible: true,
            player: null
        };
    },
    watch: {
        object(newObj, oldObj) {
            console.log(newObj.name, oldObj.name);
            this.visible = newObj.name ? false : true;
            this.player.pause();
        },
    },
    mounted() {
        this.player = this.$refs.videoPlayer
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    }
};
</script>
<style lang="scss">
.introduction-content {
    text-align: center;
    h3 {
        font-size: 26px;
    }
    p {
        font-size: 18px;
    }
    .intro-video {    
        background-color: #94632d;
        padding: 8px;
        video {
            height: auto;
            width: 100%;
        }
    }
}
</style>