<template>
    <div class="cart_section">
        <a-tag color="orange"> Cushion Hand : {{ cart?.cushion_hand?.name }} </a-tag>
        <a-tag color="orange"> Cushion Seat : {{ cart?.cushion_seat?.name }} </a-tag>
        <a-tag color="orange"> Pillow : {{ cart?.pillow?.name }} </a-tag>
        <a-tag color="orange"> Curtain : {{ cart?.curtain?.name }} </a-tag>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["cart"]),
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.cart_section {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>
