<template>
    <div class="option-drawer">
        <a-drawer placement="left" :mask="false" :closable="false" :visible="visible" width="88" wrapClassName="option-drawer-wrap">
            <div class="option-grid">
                <a-tooltip v-for="(option, index) in options" :key="index">
                    <template slot="title">
                        {{ option.name }}
                    </template>
                    <button @click="onClick(option)" :class="{ active: $store.state.option.code == option.code }">
                        <img :src="'/type/' + option.icon" :alt="option.name" />
                    </button>
                </a-tooltip>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "OptionSelector",
    computed: mapState(["object"]),
    data() {
        return {
            visible: false,
            selected: null,
            options: [
                {
                    name: "Cushion Outer",
                    code: "cushion_hand",
                    icon: "Outer.png",
                },
                {
                    name: "Cushion Seat",
                    code: "cushion_seat",
                    icon: "Seat.png",
                },
                {
                    name: "Pillow",
                    code: "pillow",
                    icon: "Pillow.png",
                },
                {
                    name: "Curtain",
                    code: "curtain_outer",
                    icon: "Curtain.png",
                },
            ],
        };
    },
    methods: {
        onClick(option) {
            this.$store.commit("onChangeOption", option);
        },
    },
    watch: {
        object(newObj, oldObj) {
            console.log(newObj.name, oldObj.name);
            this.visible = newObj.name ? true : false;
        },
    },
};
</script>

<style lang="scss">
.option-drawer {
    &-btn {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
    }
}
.option-drawer-wrap {
    .ant-drawer-content-wrapper {
        display: flex;
        align-items: center;
        box-shadow: none !important;
        .ant-drawer-content {
            height: auto;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;

            .ant-drawer-body {
                padding: 12px;
                padding-left: 20px;
            }

            .option-grid {
                display: flex;
                flex-direction: column;
                gap: 12px;

                button {
                    height: 56px;
                    width: 56px;
                    border: none;
                    overflow: hidden;
                    cursor: pointer;
                    border-radius: 5px;
                    padding: 5px;
                    border: 2px solid transparent;

                    &.active {
                        border-color: #b58445;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
